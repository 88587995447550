<template>
  <v-form v-model="valid">
    <v-card :max-width="width" min-height="300" class="mx-auto">
      <v-card-title class="pt-0">
        <div
          class="mx-auto text-center"
          style="border-top: 70px solid #2787FF;border-left: 30px solid transparent;border-right: 30px solid transparent;height: 0;width: 270px;"
        >
          <p class="mb-0" style="margin-top:calc(-25%);color:#fff;">{{titleTxt}}</p>
        </div>
      </v-card-title>
      <slot name="info"></slot>

      <!-- <v-container fluid class="pa-0 px-12">
        <template v-for="(item,index) in info">
          <div class="d-flex align-center" :key="index">
            <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
            <v-subheader class="title" style="color:rgba(39,135,255,1);">{{item.title}}</v-subheader>
          </div>
          <v-row :key="index" align="center">
            <v-col
              md="6"
              sm="12"
              cols="12"
              v-for="(n,index1) in item.content"
              :key="index1"
              class="text-left"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex">
                  <label for class="text-left">{{n.title}}</label>
                  <v-tooltip bottom v-if="(n.type=='switch'||n.type=='data')&&n.toolTip">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <v-img v-if="n.img" :src="n.toolTip"></v-img>
                    {{n.img?'':n.toolTip}}
                  </v-tooltip>
                </div>
                <v-switch
                  class="mt-0 pt-0"
                  v-if="n.type=='switch'"
                  v-model="switch1"
                  hide-details
                  :label="switch1?'包含':'未包含'"
                ></v-switch>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  v-if="n.type=='data'"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      solo
                      hide-details
                      flat
                      prepend-inner-icon="mdi-calendar-range-outline"
                      readonly
                      v-on="on"
                      style="width:100px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="zh-cn" v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-select
                v-if="n.type=='select'"
                :items="shipList"
                item-value="id"
                item-text="shipname"
                background-color="#EAF4FE"
                :placeholder="n.placeholder"
                hide-details
                flat
                solo
              ></v-select>
              <v-text-field
                v-if="n.type=='input'"
                :placeholder="n.placeholder"
                background-color="#EAF4FE"
                hide-details
                flat
                solo
              >
                <template v-slot:append-outer v-if="n.toolTip">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <v-img v-if="n.img" :src="n.toolTip"></v-img>
                    {{n.img?'':n.toolTip}}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-container>-->

      <!-- <v-container fluid class="pa-0 px-12 mb-8">
        <div class="d-flex align-center">
          <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
          <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表图片（可选）</v-subheader>
        </div>
        <v-card
          max-width="100"
          min-height="100"
          flat
          color="rgba(49,144,251,0.1)"
          class="d-flex flex-column justify-center mt-2"
        >
          <v-icon size="50">mdi-plus</v-icon>
          <v-card-text class="pt-2">上传图片</v-card-text>
        </v-card>
      </v-container>-->
      
      <v-card-actions class="pb-6 px-12">
        <v-btn
          outlined
          color="#A4ADB6"
          height="48"
          width="35%"
          style="font-size:22px;"
          @click="cancel"
        >取 消</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="#2787FF"
          width="35%"
          height="48"
          depressed
          style="color:#FFFFFF;font-size:22px;"
          @click="submit"
        >提 交</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "dialogCard",
  props: {
    width: {
      type: String
    },
    titleTxt: {
      type: String
    },
    info: {
      type: Array
    },
    shipList: {
      type: Array
    }
  },
  data() {
    return {
      valid: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      switch1: true
      // 可以根据实际情况写更复杂的弹层，根据情况扩充dialog即可
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    }
  }
};
</script>

<style>
</style>